.topbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 2.5rem;
  
    .avatar {
      display: flex;
      width: 40px;
      height: 40px;
      border: 1px solid gray;
      border-radius: 50%;
      background: lightblue;
    }
    .profile_wrap {
      display: flex;
      align-items: center;
      gap: 1rem;
      text-transform: capitalize;
      font-size: 1.5rem;
      cursor: pointer;
      position: relative;
      img {
        width: 10px;
      }
      .options {
        position: absolute;
        top: 2rem;
        width: 200px;
        height: 80px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgb(245, 238, 238);
        right: 0;
        padding: 1rem;
        box-shadow: -1px -1px 23px -12px rgba(0, 0, 0, 0.37);
        -webkit-box-shadow: -1px -1px 23px -12px rgba(0, 0, 0, 0.37);
        -moz-box-shadow: -1px -1px 23px -12px rgba(0, 0, 0, 0.37);
        border-radius: 0.3rem;
        // display: none;
        transition: all ease-in-out 300ms;
        transform: translateX(500px);
        z-index: 9999;
        span {
          background: red;
          color: #fff;
          width: 100%;
          display: flex;
          justify-content: center;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.85rem;
        }
      }
      &:hover {
        .options {
          display: flex;
          transform: translateX(0);
        }
      }
    }
  }
  