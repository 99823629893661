.paper {
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.1) !important;
  -webkit-box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.1) !important;
  -moz-box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.1) !important;
}
.h-cell {
//   font-size: 1rem !important;
  font-weight: 700 !important;
  font-family: "Poppins", sans-serif !important;
  padding: 1rem !important;
}
