.login {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
  
    .container {
      display: flex;
      flex-direction: column;
      width: 500px;
      height: fit-content;
      gap: 2rem;
      .image {
        width: 100%;
        background-color: #0f2841;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        border-radius: 0.3rem;
      }
      form {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 1rem;
        .field {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          label {
            font-size: 0.8rem;
          }
          input {
            border: 1px solid slategray;
            padding: 0.3rem 0.5rem;
            outline: none;
            border-radius: 0.3rem;
            height: 2.5rem;
          }
          .error {
            color: rgb(243, 65, 65);
            font-size: 0.8rem;
          }
        }
        .btn {
          width: 100%;
          background-color: #0f2841;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 45px;
          color: #fff;
          border-radius: 100px;
          outline: none;
          border: none;
          cursor: pointer;
        }
      }
    }
  }
  